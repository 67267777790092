import { createContactInputs } from "../../forms";
import { getCountries, postContactUs } from "../../api/contact";
import { Show, createMemo, createSignal } from "solid-js";
import { Ctrl, state } from ":mods";
import { ChatBtn } from "../chat-btn";

export function FormSection({ $ }: Ctrl.Routes.model.MicrositeViewProps) {
  const $countriesList = state.create([]);
  const $db_countries = state.createAsync(getCountries);
  const [success, setSuccess] = createSignal(false);
  const FormContact = createContactInputs();
  const char_limit = 4000;
  const $written_chars = createMemo(() => {
    const user_chars:string = FormContact.Message.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  });

  $db_countries.on((event, { value }) => {
    const countries = $db_countries.value?.data?.[0]?.data;
    const countries_names = countries.map((c) => c?.name);
    $countriesList.set(countries_names);
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    if (!FormContact.Actions.canSubmit()) return;
    const values = FormContact.Actions.getValuesLowercase();
    const selectedCountryId = $countriesList.value.find((country) => country.name == values.country)?.id;
    values.country = selectedCountryId;
    await postContactUs(values)
      .then((res) => {
        console.log("");
        $.actions.pushToast({ type: "success", message: "Form Submitted" });
        setSuccess(true);
      })
      .catch((err) => {
        $.actions.pushToast({ type: "error", message: "Error During Submit" });
      });
  };

  return (
    <section class="flex flex-col justify-center items-center bg#paper min-h-full">
      <Show when={!success()}>
        <form class="flex flex-col items-left gap-30px text-16px py-80px w-845px mx-auto">
          <div class="flex flex-col gap-10px">
            <FormContact.FullName.Label title="FullName*" class="">
              <FormContact.FullName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.FullName.Label>
            <FormContact.FullName.Input
              type="text"
              class="border-2 border#p border-solid text-16px px-2.5 py-2 mb-2 w-full bg#paper "
              placeholder=""
              name="fullName"
            />
          </div>
          <div class="flex flex-col gap-10px">
            <FormContact.Email.Label title="Email*" class="">
              <FormContact.Email.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.Email.Label>
            <FormContact.Email.Input
              type="email"
              class="border-2 border#p border-solid text-16px px-2.5 py-2 mb-2 w-full bg#paper"
              placeholder=""
            />
          </div>
          <div class="flex flex-col gap-10px">
            <FormContact.Country.Label title="Country*" class="">
              <FormContact.Country.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.Country.Label>
            <FormContact.Country.Select
              // class="form-select border-2 border#p bg#paper border-solid text-16px !py-2 w-full h-45px"
              // placeholder=""
              options={$countriesList.value}
              // optionGroupClass="text-16px text#p"

              class="form-select"
              placeholder=""
              // optionGroupClass={"!text#p absolute !mt-0 !h-max"}
            />
          </div>
          <div class="flex flex-col gap-10px">
            <FormContact.need_help_with.Label title="Subject*" class="">
              <FormContact.need_help_with.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.need_help_with.Label>
            <FormContact.need_help_with.Input
              type="text"
              class="border-2 border#p border-solid text-16px px-2.5 py-2 mb-2 w-full bg#paper"
            />
          </div>
          <div class="flex flex-col gap-10px">
            <FormContact.Message.Label title="Message*" class="">
              <FormContact.Message.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.Message.Label>
            <div class="relative">
               <FormContact.Message.TextArea class="border-2 border#p border-solid h-115px text-16px p-20px mb-2 w-full bg#paper" maxLength={char_limit} />
               <div class="absolute right-15px bottom-25px text-gray-300 text-19px">{$written_chars()}</div>

            </div>
          </div>
          <div class="flex gap-20px justify-left">
            <FormContact.ConfirmRead.Input type="checkbox" class="w-23px h-23px border-2 border#p border-solid " />
            <FormContact.ConfirmRead.Label
              title="I confirm, I have read and agree to Museums future policy and consent to sharing my information."
              class=""
            >
              <FormContact.ConfirmRead.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.ConfirmRead.Label>
          </div>

          <div class="flex gap-20px justify-left">
            <div>
              <FormContact.ReceiveAlert.Input
                type="checkbox"
                class="w-23px h-23px border-2 border#p border-solid"
                // onChange={onConfirmAlertsChange}
              />
              <span class="checkmark"></span>
            </div>

            <FormContact.ReceiveAlert.Label
              title="I would like to receive alerts and updates from Museums future."
              class=""
            >
              <FormContact.ReceiveAlert.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormContact.ReceiveAlert.Label>
          </div>

          <FormContact.Actions.Button
            class="disabled:bg#disabled bg#p w-88px h-44px text#n-50 text-16px cursor-pointer"
            onclick={onSubmit}
            statusValid={"Submit"}
            statusInvalid={"Submit"}
          />
        </form>
      </Show>
      <Show when={success()}>
        <p class="text#success my-40px">Submited Successfully!</p>
      </Show>
    </section>
  );
}
